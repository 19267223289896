@import-normalize; /* bring in normalize.css styles */
@import url("https://use.typekit.net/haq5yis.css"); /* regular 400 / semibold 600 */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400&display=swap');

/* Set padding to be subtracted from height/width instead of added to it */
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}

:root {
    --offWhite: #EFEFEF;
    --textBlack: #231F20;
}

html
{
    font-size: 1px;
}

body
{
    background: url(./../img/background.png) top left repeat;
}

.app
{
    font-family: 'avenir_next_lt_proregular';
    font-weight: normal;
    font-style: normal;
    font-size: 18rem;
    line-height: 1.5em;
    color: var(--textBlack);
}

h1
{
    font-family: 'avenir_next_lt_probold';
    font-size: 70rem;
    line-height: 1em;
    margin: 0;
    margin-bottom: 0.8em;
    color: inherit;
    text-transform: uppercase;
    filter:blur(0.7px);
}

h2, h3, h4 ,h5 ,h6
{
    font-size: 20rem;
    line-height: 1em;
    margin: 0;
    margin-bottom: 1em;
}

h2, h3
{
    font-family: 'avenir_next_lt_prodemi';
    font-size: 24rem;
    text-transform: uppercase;
    filter:blur(0.5px);
}

h3
{
    margin-bottom: 0;
}

.italic
{
    font-style: italic;
}

.bold
{
    font-family: 'avenir_next_lt_prodemi';
}

p
{
    margin: 0;
    margin-bottom: 1.5em;
}

a
{
    color: inherit;
    text-decoration: none;
    font-family: 'avenir_next_lt_prodemi';
}

button
{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-family: 'avenir_next_lt_prodemi';
    font-weight: inherit;
    font-style: normal;
    font-size: 24rem;
    text-transform: uppercase;
    color: var(--offWhite);
    filter: blur(0.7px);
}

.button
{
    background: var(--textBlack);
    padding: 8px 18px 8px 18px;
    border: none;
    cursor: pointer;
    font-family: 'avenir_next_lt_prodemi';
    font-weight: inherit;
    font-style: inherit;
    font-size: 20rem;
    text-transform: uppercase;
    color: var(--offWhite);
    filter: blur(0.7px);
}

.button.black
{
    background: var(--offWhite);
    color: var(--textBlack);
}

.material-icons
{
    vertical-align: text-bottom;
}

.page
{
    min-height: 100vh;
    padding: 100px 100px 160px 100px;
    width: 1300px;
    margin: 0 auto;
}

.page.black
{
    background: #000000;
    color: var(--offWhite);
}

.page.white
{
    background: var(--offWhite);
}

@media only screen and (max-width: 1300px) {
    .page
    {
        width: 100vw;
        padding: 7.7vw 7.7vw 10vw 7.7vw;
    }
}