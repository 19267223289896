#credits-text
{
    margin-top: 40px;
    text-align: justify;
    font-size: 12rem;
    text-transform: uppercase;
}

#credits-text p strong
{
    text-transform: uppercase;
    font-family: 'avenir_next_lt_proregular';
    font-size: 18rem;
}