#contact-columns
{
    display: flex;
    gap: 50px 150px;
    flex-direction: row;
    margin-top: 40px;
}

.contact-column
{
    width: 30%;
}

.contact-text
{
    white-space: pre-wrap;
}

.contact-text a
{
    color: var(--textYellow);
}

@media only screen and (max-width: 1000px) {

    #contact-columns
    {
        flex-direction: column;
    }

    .contact-column
    {
        width: 50%;
    }
}

@media only screen and (max-width: 700px) {

    .contact-column
    {
        width: 100%;
    }
}