/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 5, 2024 */

@font-face {
    font-family: 'avenir_next_lt_prodemi';
    src: url('avenirnextltpro-demi-webfont.woff2') format('woff2'),
         url('avenirnextltpro-demi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'avenir_next_lt_probold';
    src: url('avenirnextltpro-bold-webfont.woff2') format('woff2'),
         url('avenirnextltpro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir_next_lt_proitalic';
    src: url('avenirnextltpro-it-webfont.woff2') format('woff2'),
         url('avenirnextltpro-it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir_next_lt_proregular';
    src: url('avenirnextltpro-regular-webfont.woff2') format('woff2'),
         url('avenirnextltpro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}