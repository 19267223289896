#watch-flex
{
    display: flex;
    gap: 80px
}

#watch-vod
{
    width: 250px;
}

#watch-vod img
{
    width: 100%;
    border-radius: 10px;
    margin-bottom: 30px;
}

#watch-screenings
{
    flex-grow: 1;
}

#watch-screenings a
{
    display: block;
    padding: 8px 16px;
    background: var(--offWhite);
    color: var(--textBlack);
    margin-bottom: 8px;
}


@media only screen and (max-width: 1000px) {

    #watch-flex
    {
        display: block;
    }

    #watch-flex div
    {
        width: 100%;
    }
}