#menu
{
    height: 50px;
    padding-top: 12px;
    text-align: center;
    background: #000000;
    position: fixed;
    top: 0;
    width: 100%;
    color: var(--textBright);
    z-index: 1000;
    -webkit-backface-visibility: hidden;
    overflow-y: hidden;
}

#menu.open
{
    height: auto;
    padding-bottom: 20px;
}

#main
{
    display: inline-flex;
    flex-direction: row;
}

#main button
{
    margin: 0 16px;
}

#main button.active, #lang button.active
{
    color: #777777;
}

#main-hamburger
{
    height: 50px;
    display: none;
}

#lang
{
    position: absolute;
    right: 0;
    top: 18px;
    margin-right: 20px;
}

#lang button
{
    float: left;
    font-size: 12px;
    margin: 0 4px;
    letter-spacing: 0.27em;
}

@media only screen and (max-width: 1300px) 
{
    #main
    {
        flex-direction: column;
    }
    
    #main button
    {
        margin: 0 0 10px 0;
    }

    #main-hamburger
    {
        display: block;
    }
}