:root 
{
    --gap: 0px;
}

#stills
{
    padding: 0;
    min-height: auto;
    aspect-ratio: (6 * 4) / (5 * 3);
}

.gallery-flex
{
    clear: both;
    width: calc(100% + var(--gap));
    display: block;
}

.gallery-flex:last-child:after, .gallery-flex::after
{
    content: "";
    clear: both;
    display: table;
}

.gallery-flex img
{
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
}

.gallery-flex div
{ 
    float: left;
    margin: 0 var(--gap) var(--gap) 0;
    width: 216.66px;
}

.gallery-flex div:nth-child(n+31)
{
    display: none;
}

@media only screen and (max-width: 1300px) {
    .gallery-flex div
    { 
        width: calc(100vw / 6);
    }
}

@media only screen and (max-width: 1000px) {
    .gallery-flex div
    { 
        width: calc(100vw / 3);
    }

    #stills
    {
        aspect-ratio: (3 * 4) / (10 * 3);
    }
}