#home
{
    background-color: #000000;
    min-height: 100vh;
    text-align: center;
    padding-bottom: 100px;
}

#logo
{
    width: 100%;
    max-width: 1050px;
    height: auto;
    margin: 0 auto;
    margin-top: 25vh;
    margin-bottom: 3vh;
}

#logos
{
    margin-top: 15vh;
    width: 80%;
}

.trailerButton
{
    display: inline-block;
    padding: 8px 18px 8px 12px;
    background: var(--offWhite);
    font-family: 'avenir_next_lt_prodemi';
    font-size: 20rem;
    text-transform: uppercase;
    color: var(--textBlack);
    filter: blur(0.7px);
}