.poster-poster
{
    float: left;
    width: 300px;
}

.poster-poster a
{
    width: inherit;
}

.poster-poster img
{
    width: inherit;
    height: auto;
    border-radius: 10px;
    box-shadow: 6px 6px #dadada;
}

.poster-text
{
    float: left;
    width: 800px;
    padding-left: 60px;
}

.poster-content
{
    overflow: auto;
}

.poster-cards
{
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    height: 325px;
    margin-top: -170px;
    overflow: hidden;
    background: var(--offWhite);
}

.poster-cards img
{
    width: inherit;
    height: auto;
    position: relative;
}

.poster-cards img:nth-child(2)
{
    top: -340px;
}

@media only screen and (max-width: 1300px) {

    .poster-poster, .poster-text
    {
        float: none;
        width: 100%;
        padding-left: 0px;
        margin-bottom: 60px;
    }

    .poster-cards
    { 
        height: calc(100vw / 4); /* 325px */
        margin-top: calc(-100vw / 7.647); /* -170px */
    }

    .poster-cards img:nth-child(2)
    {
        top: calc(-100vw / 3.8235); /* -340px */
    }
}